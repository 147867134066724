import "./App.css";
import React, { useState } from "react";
import PlayerComponent from "./components/PlayerComponent";
import PlaylistComponent from "./components/PlaylistComponent";
import "react-h5-audio-player/lib/styles.css";

function App() {
  const [radio, setRadio] = useState();
  const changeRadioLink = (data) => {
    setRadio();
    setRadio(data);
  };
  const toggleList = () => {
    let list = document.querySelector(".playlists");
    list.classList.toggle("active");
  };
  return (
    <div className="App">
      <div className="content">
        <div className="burger" onClick={toggleList}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="playlists">
          <PlaylistComponent changeRadio={changeRadioLink} />
        </div>
        <div className="player">
            {radio != null ? (
              <PlayerComponent radio={radio} />
            ) : (
              <p>Select a Playlist</p>
            )}
        </div>
      </div>
    </div>
  );
}

export default App;
