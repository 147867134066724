import axios from "axios";
import React, { useEffect, useState } from "react";
import { initAuth, getAllPermissions } from "../firebaseAuth";
import { signInWithEmailAndPassword } from "firebase/auth";

const PlaylistComponent = ({ changeRadio }) => {
  const [radios, setRadios] = useState([]);
  const [premium, setPremium] = useState([]);
  const [uPermissions, setUPermissions] = useState();
  const [loading, setLoading] = useState(true);
  const [chosenRadio, setChosenRadio] = useState();
  const [loginData, setLoginData] = useState();
  const [errMsg, setErrMsg] = useState();
  const [user, setUser] = useState();
  const auth = initAuth();

  //run get all permissions before anything else
  useEffect(() => {
    if (!uPermissions) {
      getAllPermissions().then((data) => {
        setUPermissions(data);
      });
    }
  }, []);

  const getRadios = () => {
    console.log("getRadios");
    axios
      .get("https://radio.tiktikradio.com/api/stations")
      .then((response) => {
        let data = [];
        response.data.forEach((radio) => {
          data.push({
            id: radio.id,
            name: radio.name,
            link: radio.listen_url,
          });
        });
        setRadios(data);
      })
      .catch((error) => {
        console.log(error);
        setRadios([]);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const switchLink = (data) => {
    changeRadio(data);
  };

  const selectedRadio = (radio) => {
    if (radio === chosenRadio) {
      return "radio-item selected";
    }
    return "radio-item";
  };

  const logIn = async (e) => {
    e.preventDefault();
    await signInWithEmailAndPassword(auth, loginData.user, loginData.pass)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setUser(user);
        setErrMsg();
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrMsg(errorMessage);
        console.log(errorMessage);
      });
  };

  const logout = () => {
    auth.signOut();
    switchLink();
  };

  const checkUser = () => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        setPremium(uPermissions[authUser.email]);
      } else {
        setUser(null);
        console.log("no user");
        // console.log(uPermissions);
        setPremium(uPermissions["free"]);
      }
    });
  };

  useEffect(() => {
    console.log("useEffect");
    if (!uPermissions) return;
    if (radios.length === 0) {
      getRadios();
    }
    if (!user) {
      checkUser();
    }
  }, [uPermissions]);

  return (
    <div className="inner">
      <h3>Playlists</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="item-container">
            {radios.map((radio) => {
              let classes = selectedRadio(radio);
              if (!premium.includes(radio.id)) return;
              return (
                <div className={classes} key={radio.id}>
                  <p>{radio.name}</p>
                  <button
                    onClick={() => {
                      switchLink(radio);
                      setChosenRadio(radio);
                    }}
                  >
                    Play
                  </button>
                </div>
              );
            })}
          </div>
          <div className="item-container premium">
            {errMsg && <p className="alert">{errMsg}</p>}
            {!user && (
              <form>
                <h4>Premium</h4>
                <small>Log in to access premium content</small>
                <input
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={(e) => {
                    setLoginData({ ...loginData, user: e.target.value });
                  }}
                />
                <input
                  name="pass"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setLoginData({ ...loginData, pass: e.target.value });
                  }}
                />
                <input type="submit" value="Log In" onClick={logIn} />
              </form>
            )}
            {user && (
              <button
                onClick={() => {
                  logout();
                }}
              >
                Log Out
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PlaylistComponent;
