import goldRadio from './assets/gold-radio.png';
import qktRadio from './assets/qkt-radio.png';
import bearsRadio from './assets/bears-factory.jpeg';

const radioImgs = {
    2: goldRadio,
    4: qktRadio,
    5: bearsRadio,
};

export default radioImgs;