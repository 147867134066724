import React, { useEffect, useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import radioImgs from "../radioImg";
const PlayerComponent = ({ radio }) => {
  const [status, setStatus] = useState("initial");
  const [radioData, setRadioData] = useState();
  const audio = useRef();

  const loadRadio = () => {
    console.log("loadRadio");
    setRadioData(radio);
    setStatus("ready");
  };

  useEffect(() => {
    if (radio !== radioData) {
      console.log("radioLink !== radio");
      setStatus("initial");
      loadRadio();
    }
  }, [radio]);
  return (
    <div className="inner">
      {status == "error" && <div className="alert">Radio is not available</div>}
      {status == 'ready' && (
        <>
          <div className="radio-info">
          <h3>{radioData.name}</h3>
            {radioImgs[radioData.id] && (
              <img src={radioImgs[radioData.id]} alt={radioData.name} />
            )}
          </div>
          <div className="audio">
            <AudioPlayer
              autoPlay
              src={radioData.link}
              showJumpControls={false}
              layout="horizontal"
              customAdditionalControls={[]}
              customProgressBarSection={[RHAP_UI.CURRENT_TIME]}
              onPlay={(e) => console.log("onPlay")}
              onError={(e) => setStatus("error")}
            />
          </div>
        </>
      )}
      {status == "initial" && <p>Loading...</p>}
    </div>
  );
};

export default PlayerComponent;
