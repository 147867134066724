import { useEffect, useState } from "react";
import {
  getAllPermissions,
  newUser,
  initAuth,
  saveUserToFirestore,
  updateUserPermissions,
} from "./firebaseAuth";
import "./Admin.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import { waitForPendingWrites } from "firebase/firestore";
const AdminPage = () => {
  const [uPermissions, setUPermissions] = useState();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loginData, setLoginData] = useState();
  const [editUser, setEditUser] = useState();
  const [errMsg, setErrMsg] = useState();
  const [radioInfo, setRadioInfo] = useState([]);
  const auth = initAuth();

  useEffect(() => {
    console.log("useEffect");
    if (!uPermissions) {
      setLoading(true);
      getAllPermissions().then((data) => {
        setUPermissions(data);
        setLoading(false);
        if (auth.currentUser) {
          if (data[auth.currentUser?.email].includes("aqifMaqiteva")) {
            setIsAdmin(true);
          }
        }
      });
    }
    if (radioInfo.length === 0) {
      setLoading(true);
      getRadioInfo();
    }
  }, []);

  const getRadioInfo = () => {
    axios
      .get("https://radio.tiktikradio.com/api/stations")
      .then((response) => {
        let data = [];
        response.data.forEach((radio) => {
          data.push({
            id: radio.id,
            name: radio.name,
          });
        });
        setRadioInfo(data);
      })
      .catch((error) => {
        console.log(error);
        setRadioInfo([]);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //use react lifecycle to sign user out when component unmounts

  const logIn = async (e) => {
    e.preventDefault();
    setErrMsg();
    await signInWithEmailAndPassword(auth, loginData.user, loginData.pass)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (uPermissions[user.email].includes("aqifMaqiteva")) {
          setIsAdmin(true);
        }
        setLoginData();
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setIsAdmin(false);
        setErrMsg(errorMessage);
        console.log(errorMessage);
      });
    setLoginData();
  };

  const createUser = async (e) => {
    e.preventDefault();
    console.log("createUser");
    setErrMsg("");
    let success = await newUser(loginData.user, loginData.pass);
    if (success) {
      await saveUserToFirestore(loginData.user);
      getAllPermissions().then((data) => {
        setUPermissions(data);
      });
    } else {
      setErrMsg("Something went wrong, try again");
    }
    setLoginData();
    console.log("createUser end");
  };

  const showEdit = (user, permissions) => {
    setEditUser({ email: user, permissions: permissions });
  };

  const editUPermissions = async (e) => {
    e.preventDefault();
    console.log("editUPermissions");
    //from string in array to int in array only if different from given permissions
    let permissions = editUser.permissions.map((p) => p === "aqifMaqiteva" ? p : parseInt(p));
    // let permissions = editUser.permissions.map((p) => parseInt(p));
    await updateUserPermissions(editUser.email, permissions);
    getAllPermissions().then((data) => {
      setUPermissions(data);
    });
    setEditUser();
  };

  return (
    <div className="App">
      <div className="content admin">
        <div className="pannel">
          {loading && <p>Loading...</p>}
          {!loading && !isAdmin && (
            <div className="login">
              <h3>Admin</h3>
              {!errMsg && <p>Log in to access admin pannel</p>}
              {errMsg && <p className="alert">{errMsg}</p>}
              <form>
                <input
                  type="text"
                  placeholder="Email"
                  onChange={(e) => {
                    setLoginData({ ...loginData, user: e.target.value });
                  }}
                />
                <input
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setLoginData({ ...loginData, pass: e.target.value });
                  }}
                />
                <input type="submit" value="Log In" onClick={logIn} />
              </form>
            </div>
          )}
          {isAdmin && (
            <div className="admin-logged">
              <div className="card">
                <div>
                  <h4>Create New User</h4>
                  {errMsg && <p className="alert">{errMsg}</p>}
                  <form autoComplete="off">
                    <input
                      type="text"
                      placeholder="Email"
                      onChange={(e) => {
                        setLoginData({ ...loginData, user: e.target.value });
                      }}
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={(e) => {
                        setLoginData({ ...loginData, pass: e.target.value });
                      }}
                    />
                    <input type="submit" value="Create" onClick={createUser} />
                  </form>
                </div>
              </div>
              <div className="card">
                {radioInfo.length > 0 && (
                  <div className="radioInfo">
                    {radioInfo.map((radio) => {
                      return (
                        <div className="item" key={radio.id}>
                          <p>{radio.name}</p>
                          <p>{radio.id}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="card">
                <div className="uPermissions">
                  <p>Manage Users</p>
                  {uPermissions && (
                    <ul className="permissions">
                      {Object.keys(uPermissions).map((user) => {
                        return (
                          <li key={user}>
                            {user}{" "}
                            <button
                              onClick={() => showEdit(user, uPermissions[user])}
                            >
                              Edit
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
          {editUser && (
            <div className="editPermissions">
              <form>
              <p>Edit Permissions</p>
                <input
                  value={editUser.email}
                  type="text"
                  placeholder="Email"
                  disabled={true}
                />
                <input
                  type="text"
                  name="permissions"
                  placeholder="Permissions"
                  value={editUser.permissions}
                  onChange={(e) => {
                    //from coma separated to array
                    let perm = e.target.value.split(",");
                    perm = perm.map((p) => p.trim());
                    setEditUser({ ...editUser, permissions: perm });
                  }}
                />
                <input type="submit" value="Save" onClick={editUPermissions} />
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
