import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
const firebaseConfig = require("./qkt-radio-firebase-adminsdk-7y6f0-9d2a47758c.json");

const authEndpoint = "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=" + firebaseConfig.apiKey;

const app = initializeApp(firebaseConfig);

const initAuth = () => {
  const auth = getAuth(app);
  return auth;
};



const getAllPermissions = async () => {
  const db = getFirestore(app);
  //get document by id
  const docRef = doc(db, "permissions", "userPermission");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return {};
};

const saveUserToFirestore = async (email) => {
  const db = getFirestore(app);
  const docRef = doc(db, "permissions", "userPermission");
  await setDoc(docRef, { [email]: [] }, { merge: true });
  console.log("saved");
  return;
};

const updateUserPermissions = async (email, permissions) => {
  const db = getFirestore(app);
  const docRef = doc(db, "permissions", "userPermission");
  await setDoc(docRef, { [email]: permissions }, { merge: true });
  console.log("saved");
  return;
};


const newUser = async (email, pass) => {
  return axios.post(authEndpoint, {
    email: email,
    password: pass,
    returnSecureToken: true
  }).then((response) => {
    console.log(response);
    return true;
  }).catch((error) => {
    console.log(error);
    return false;
  });
};


export { initAuth, getAllPermissions, saveUserToFirestore, newUser, updateUserPermissions };
